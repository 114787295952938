import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'

const lg = {
  initLoad() {
    this.initLightGallery();
  },

  initLightGallery() {
    const $lg = $('#lightgallery');
    if ($lg.length === 0) return false;

    lightGallery(document.getElementById('lightgallery'), {
      plugins: [lgZoom, lgThumbnail, lgFullscreen],
      selector: '.lightgallery__img',
      // showZoomInOutIcons: true,
      download: false,
      licenseKey: true,
      mobileSettings: { controls: true, showCloseIcon: true, download: false, }
    })
  },
};

module.exports = lg;
