// import fullpage from 'fullpage.js'
import './fullpage.parallax.min.js';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

const fullp = {
  fullPageInstance: '',

	initReady() {
		this.initFullPage();
	},


	initFullPage() {
		const $el = $('#fp');
    if ($el.length === 0) return false;

    new fullpage('#fp', {
      parallax: true,
      // parallaxKey: '4A4A7DC3-EC374869-AC76D7E7-257D55EB',
      // parallaxKey: 'aW5tYXRlcmlhLm5ldF80UWRjR0Z5WVd4c1lYZz1JMEE=', // LICENZA DOMINIO INMATERIA
      parallaxKey: 'YmVhdXR5bm92YWVsaXRlLml0X2JZdmNHRnlZV3hzWVhnPThVMg==', // LICENZA DOMINIO BEAUTYNOVAELITE.IT
      scrollingSpeed: 1000,
      autoScrolling: true,
      fitToSection: false,
      scrollBar: true,
    });
	},

}

module.exports = fullp;
