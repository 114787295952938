import "intersection-observer";
import scrollama from "scrollama";

const common = {
  scroller: '',
  scroller2: '',

  initReady() {
		this.bindScrollingLink();
    this.setCurrentLabelMenu();
    this.initVideo();
    this.initSideForm();
    this.initEnterViewport();
    this.initNumbersAnim();
	},
  goToPos: (ref, offset = 0) => {
    const $ref = (typeof ref === 'string') ? $(ref) : ref;

    if ($(ref).length === 0) return false;
    const myPos = $ref.offset().top - offset;

    $('html,body').animate({
      scrollTop: myPos,
    });
  },

  bindScrollingLink() {
    $(document)
      .on('click', '.goToLink', function () {
        const target = $(this).data('target');
        const offset = $(this).data('offset') || 0;

        if (typeof target !== 'undefined') {
          common.goToPos(target, offset);
        }
      });
  },

  setCurrentLabelMenu: () => {
    $('.header__menu > ul a.js-current-language').removeClass('js-current-language');
    $(`.header__menu > ul a[href="${window.location.href}"]`).addClass('js-current-language');
  },

  initVideo() {
		const $el = $('#video');
		if($el.length) {
			const src = $el.data('src');

			$el.find('source').attr('src', src);
			// $el.attr('preload', 'auto')
			$el[0].load()
      $('.top-home').addClass('play')
		}
	},

  initSideForm() {
    const $el = $('.side-contact__header__toggle');
		if($el.length) {

      $el.on('click', function(){
        if($el.hasClass('active')){
          $el.removeClass('active')
          $('.side-contact').removeClass('active');
        } else {
          $el.addClass('active')
          $('.side-contact').addClass('active');
        }
      })
		}
  },

  initEnterViewport() {
    const $el = $('.anim');
		if($el.length) {
      common.scroller = scrollama()
      common.scroller
        .setup({
          step: '.anim',
          // once: true,
          offset: 0.8
        })
        .onStepEnter((response) => {
          // { element, index, direction }
          const delay = $(response.element).data('delay') * 1000 || 0

          setTimeout(() => {
            $(response.element).addClass('in-view')
          }, delay);
        })
    }
  },

  animateValue(el, start, end, duration) {
    // assumes integer values for start and end

    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;

    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range)).toLocaleString();
        if(el)
          el.innerHTML = value;
        if (value >= end) {
            clearInterval(timer);
        }
    }

    timer = setInterval(run, stepTime);
    run();
  },

  initNumbersAnim() {
    const $el = $('.number__value');
		if($el.length) {
      common.scroller2 = scrollama()
      common.scroller2
        .setup({
          step: '.number__value',
          // once: true,
          offset: 0.8
        })
        .onStepEnter((response) => {
          const target = $(response.element).data('limit')

          common.animateValue($(response.element).find('span')[0], 0, target, 4000);
        })
    }
  }
}

module.exports = common;
