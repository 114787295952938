import 'jquery-sticky'

const sticky = {
	initReady() {
		this.initStickyElement();
	},

	initStickyElement() {
		const $el = $('.js-sticky');
		const topSpacing = $(window).outerWidth() < 768 ? 72 : 66;
		if($el.length) {
			$(".js-sticky").sticky({topSpacing});
		}
	},

}

module.exports = sticky;
