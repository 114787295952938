import 'slick-carousel'

const product = {
	initLoad() {
		this.initProductGallery();
	},

	initProductGallery() {
		const $el = $('.product-gallery');
		if($el.length) {
			$el.slick({
				dots: false,
				infinite: false,
				speed: 300,
				slidesToShow: 2,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
						}
					}
				]
			});
		}
	},

}

module.exports = product;
