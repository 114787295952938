import $ from 'jquery'
import '../sass/main.scss';
import common from './common';
import menu from './menu';
import lazyLoad from './lazyLoad';
import smoothState from './smoothState';
import fullp from './fullpage';
import sticky from './sticky';
import product from './product';
import lightgallery from './lightgallery';
import news from './news';

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    smoothState.initReady();
    common.initReady();
    menu.initReady();
    fullp.initReady();
    sticky.initReady();
  },
  initLoad() {
  	lazyLoad.initLoad();
    product.initLoad();
    lightgallery.initLoad();
    news.initLoad();
  },
  rebuildAllEvents() {
    menu.initReady();
    fullp.initReady();
    common.initReady();
    sticky.initReady();
    lazyLoad.initLoad();
    product.initLoad();
    lightgallery.initLoad();
    news.initLoad();
  },
  initScroll(){
    menu.initScroll()
  },
  initResize() {
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuildAllEvents();
});

$(window).on('load', () => {
  $('#preloader').addClass('loaded');
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
