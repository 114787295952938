import Isotope from 'isotope-layout'

const news = {
	iso: '',

	initLoad() {
		this.initIsotope();
	},

	initIsotope() {
		const $el = $('.js-isotope');

		if($el.length) {
			const grid = document.querySelector('.js-isotope');
			news.iso = new Isotope( grid, {
				// options...
				itemSelector: '.js-isotope-selector',
				layoutMode: 'fitRows',
				stagger: 50,
				transitionDuration: '0.5s'
			});

			$('.filters__item__button').on( 'click', function() {
				const filterValue = $(this).attr('data-filter');
				news.iso.arrange({ filter: filterValue });
			});
		}
	},

}

module.exports = news;
